/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import theme from "../theme/theme"
import { GridThemeProvider, BaseCSS } from "styled-bootstrap-grid"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from '../theme/global';
import Helmet from "react-helmet"

const TestingLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={theme}>
        <React.Fragment>
          <GlobalStyles/>
          <Helmet>
            <link href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,800&text=%E2%84%A2%C2%AE" rel="stylesheet" />
          </Helmet>
          <BaseCSS/>
          {children}
        </React.Fragment>
      </GridThemeProvider>
    </ThemeProvider>
  )
}

export default TestingLayout
