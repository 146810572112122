/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import TestingLayout from "../components/TestingLayout"
import Spacer from "../prismic/components/PrismicSpacer"
import HubspotForm from "../components/Forms/HubspotForm"
import DownArrow from "../components/DownArrow"
import BigTextHero from "../components/BigTextHero/BigTextHero"

const Layout = () => {

  return (
    <TestingLayout>
      <Spacer/>
      <BigTextHero 
        image={{imageInfo: {src: 'https://i.picsum.photos/id/190/2000/1500.jpg'}, alt: 'hero'}}
        line1={'We Manufacture'} 
        line2={'Flighting'} 
        line3={'Any flighting, any auger, any application'} 
      />
      <Spacer/>
    </TestingLayout>
  )
}

export default Layout
